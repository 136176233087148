import React from 'react'

import ClickWall from '../componentsNew/click-wall'

import '../scss/inter-font.scss'

const phone = '866-430-8512'

const NewLPSuccess = ({ location }) => {
  React.useEffect(() => {
    window?.ga &&
      window.ga('send', 'screenview', {
        appName: 'medicarecompanion.org',
        screenName: 'thank',
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="inter-font">
      <ClickWall phone={phone} const data={location?.state?.data} />
    </div>
  )
}

export default NewLPSuccess
